body {
  min-height: 100vh !important;
  min-height: -webkit-fill-available;
  background-color: #f16529;
}

html {
  min-height: -webkit-fill-available;
}

#root {
  background-color: white;
  min-height: 100vh !important;
}

.nav-border {
  border-bottom-width: 13px;
}

@media (max-width: 991px) {
  button {
    -webkit-user-select: none;
  }
  *::-webkit-scrollbar {
    width: 0px;
  }
}
